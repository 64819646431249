import { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { Row, Col, Card, Toast, Modal, Button } from "react-bootstrap";
import { puzzle as originalPuzzle } from "./puzzle";
import raw from "./words.txt";

function Strands() {
  let _puzzle = JSON.parse(JSON.stringify(originalPuzzle));
  const [puzzle, setPuzzle] = useState(_puzzle);
  const setAllCellsUnclicked = () => puzzle.grid.forEach((row) => row.forEach((cell) => (cell.clicked = false)));
  const setAllCellsUnfinalised = () => puzzle.grid.forEach((row) => row.forEach((cell) => (cell.finalised = false)));

  //STATE
  const [allWords, setAllWords] = useState([]);
  const [currWord, setCurrWord] = useState(null);
  const [currWordIndex, setCurrWordIndex] = useState(null);
  const [wordIndexes, setWordIndexes] = useState([]);
  const [hintIndex, setHintIndex] = useState(2);
  const [guessedRandomWords, setGuessedRandomWords] = useState([]);
  const [guessedWords, setGuessedWords] = useState([]);
  const [showAnswerCorrectToast, setShowAnswerCorrectToast] = useState(false);
  const [showSpangramCorrectToast, setShowSpangramCorrectToast] = useState(false);
  const [goToConnections, setGoToConnections] = useState(false);
  //STATE

  console.log("puzzle", puzzle);
  console.log("currWord", currWord, "currWordIndex", currWordIndex, "wordIndexes", wordIndexes);
  console.log("guessedRandomWords", guessedRandomWords, "guessedWords", guessedWords);
  const cols = ["red", "blue", "green", "orange", "purple", "gold"];

  useEffect(() => {
    setAllCellsUnclicked();
    setAllCellsUnfinalised();
    if (!allWords.length) {
      fetch(raw)
        .then((res) => res.text())
        .then((text) => {
          setAllWords(text.split("\n").map((word) => word.toUpperCase()));
        });
    }
  }, []);

  const getCol = (cell) => {
    if (cell.finalised) {
      return { backgroundColor: cols[cell.word], color: "white" };
    }
    if (!cell.clicked) {
      return { backgroundColor: "white", color: "black" };
    }
    return {
      backgroundColor: "black",
      color: "white",
    };
  };

  const checkWord = () => {
    const isWord = allWords.includes(currWord);
    const findInPuzzleWords = (word) => puzzle.words.findIndex((word) => word === currWord);
    const isPuzzleWord = findInPuzzleWords(currWord) !== -1;
    //TODO: just matches the strings, doesn't actually check the stored numbers in the sequence
    if (isPuzzleWord) {
      wordIndexes.forEach(([rowIndex, cellIndex]) => {
        puzzle.grid[rowIndex][cellIndex].clicked = true;
        puzzle.grid[rowIndex][cellIndex].finalised = true;
      });
      setPuzzle(JSON.parse(JSON.stringify(puzzle)));
      setGuessedWords([...guessedWords, findInPuzzleWords(currWord)]);
      setHintIndex(2);
      if (findInPuzzleWords(currWord) === puzzle.words.length - 1) {
        setShowSpangramCorrectToast(true);
        setTimeout(() => setShowSpangramCorrectToast(false), 5000);
      } else {
        setShowAnswerCorrectToast(true);
        setTimeout(() => setShowAnswerCorrectToast(false), 5000);
      }
      if (guessedWords.length === puzzle.words.length - 1) {
        setTimeout(() => setGoToConnections(true), 2000);
      }
    } else {
      if (isWord) {
        if (guessedRandomWords.includes(currWord)) {
          alert("YOU ALREADY GUESSED THAT WORD!");
        } else {
          let i = hintIndex;
          const hintWords = puzzle.words.filter((w, i) => !guessedWords.includes(i)).join(" ");
          console.log("hint words", hintWords, "i", i);
          if (hintWords[i] === " ") {
            setHintIndex(hintIndex + 1);
            i++;
          }

          alert(
            `NICE WORD "${currWord}", BUT TRY AGAIN! The more proper words you guess, the more words get revealed in the hint: ${hintWords
              .slice(0, i)
              .split(" ")
              .join(", ")}`
          );
          setHintIndex(i + 2);
          setGuessedRandomWords([...guessedRandomWords, currWord]);
        }
      } else {
        alert("NOT A WORD!");
      }
    }

    setCurrWord(null);
    setCurrWordIndex(null);
    setWordIndexes([]);
    setAllCellsUnclicked();
  };

  const handleLetterClick = (cell, rowIndex, cellIndex) => {
    const [currRow, currCol] = currWordIndex || [null, null];
    if (cell.finalised) return;
    if (currRow !== null) {
      const tooFar = Math.abs((currRow - rowIndex) % 6) > 1 || Math.abs((currCol - cellIndex) % 7) > 1;
      const lastPressedTwice = currRow !== null && currRow === rowIndex && currCol === cellIndex;
      const previousPressedTwice = wordIndexes.map((e) => e.toString()).includes(`${rowIndex},${cellIndex}`);
      if (tooFar) {
        alert("Too far away!");
        return;
      }
      if (lastPressedTwice) {
        checkWord();
        return;
      }
      if (previousPressedTwice) {
        console.log(
          "Already clicked, wordIndexes =",
          wordIndexes.map((e) => e.toString()),
          "rowIndex,cellIndex =",
          `${rowIndex},${cellIndex}`
        );
        return;
      }
    }
    setCurrWord((currWord) => `${currWord ?? ""}${cell.letter}`);
    cell.clicked = true;
    setCurrWordIndex([rowIndex, cellIndex]);
    setWordIndexes([...wordIndexes, [rowIndex, cellIndex]]);

    setPuzzle(JSON.parse(JSON.stringify(puzzle)));
  };

  return (
    <div style={{ minWidth: "100%", padding: 10 }}>
      <Modal show={goToConnections}>
        <Modal.Header>
          <Modal.Title>Congratulations!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Now let's move on to Connections!</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={() => (window.location.href = "/rentia22/connections")}>
            Go to Connections
          </Button>
        </Modal.Footer>
      </Modal>
      <Col>
        <Row style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
          <Card border='info' style={{ width: "18rem" }}>
            <Card.Header>TODAY'S THEME</Card.Header>
            <Card.Body>
              <Card.Title>Taylor made for you</Card.Title>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <h6>Note: don't drag, just click. Click twice when you think you're done!</h6>
        </Row>
        <Row>
          {puzzle.grid.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: "flex", justifyContent: "center" }}>
              {row.map((cell, cellIndex) => (
                <Avatar key={cellIndex} style={{ ...getCol(cell), margin: 4, cursor: "pointer" }} onClick={() => handleLetterClick(cell, rowIndex, cellIndex)}>
                  {cell.letter}
                </Avatar>
              ))}
            </div>
          ))}
        </Row>
      </Col>
      <Toast className='d-inline-block m-1' bg='success' onClose={() => setShowAnswerCorrectToast(false)} show={showAnswerCorrectToast} animation={true}>
        <Toast.Header>
          <strong className='me-auto'>Well done!</strong>
        </Toast.Header>
        <Toast.Body className='text-white'>That's correct!</Toast.Body>
      </Toast>
      <Toast className='d-inline-block m-1' bg='warning' onClose={() => setShowSpangramCorrectToast(false)} show={showSpangramCorrectToast} animation={true}>
        <Toast.Header>
          <strong className='me-auto'>Well done!</strong>
        </Toast.Header>
        <Toast.Body className='text-white'>You solved the Spangram!</Toast.Body>
      </Toast>
    </div>
  );
}

export default Strands;
