function FinalPage() {
    return (
      <div style={{ padding: 10 }}>
        <h1>Well done!</h1>
        <h3>You have completed all the puzzles!</h3>
        <h3>Now go looking for this:</h3>
        <h5 style={{ fontWeight: "bold", color: "green" }}>GREEN</h5>
        <h5>PORCH 🏡</h5>
        <h5>TABLE ┬─┬﻿ ノ( ゜-゜ノ)</h5>
        <p>Bye 👋</p>
      </div>
    );
  }

  export default FinalPage;