import React from 'react';
import './navHeader.css'; // Assuming you will style the buttons in this CSS file

const NavHeader = () => {
  const navigateToRentia22 = () => {
    window.location.href = '/rentia22';
  };

  const navigateToGameCounter = () => {
    window.location.href = '/games/counter';
  };

  return (
    <nav>
      <button className="colorful-button" onClick={navigateToRentia22}>
        <span className="rainbow-text">Rentia 22 🎈</span>
      </button>
      <button className="grey-button" onClick={navigateToGameCounter}>
        Game Counter
      </button>
    </nav>
  );
};

export default NavHeader;