import React, { useState, useEffect } from 'react';
import './counter.css'; // Assuming you will style the buttons in this CSS file

const Counter = ({ name, colorClass }) => {
  const [count, setCount] = useState(0);
  const [lastSaved, setLastSaved] = useState(0);

  useEffect(() => {
    const savedCount = localStorage.getItem(`counter-${name}`);
    const savedLastSaved = localStorage.getItem(`last-saved-${name}`);
    if (savedCount !== null) setCount(parseInt(savedCount));
    if (savedLastSaved !== null) setLastSaved(parseInt(savedLastSaved));
  }, [name]);

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.setItem(`last-saved-${name}`, count);
      setLastSaved(count);
    }, 10000);
    return () => clearTimeout(timer);
  }, [count, name]);

  const incrementCounter = () => {
    setCount(prevCount => {
      const newCount = prevCount + 1;
      localStorage.setItem(`counter-${name}`, newCount);
      return newCount;
    });
  };

  const decrementCounter = () => {
    setCount(prevCount => {
      const newCount = prevCount - 1;
      localStorage.setItem(`counter-${name}`, newCount);
      return newCount;
    });
  };

  const incrementCounterBy = (value) => {
    setCount(prevCount => {
      const newCount = prevCount + value;
      localStorage.setItem(`counter-${name}`, newCount);
      return newCount;
    });
  };

  const resetCounter = () => {
    setCount(0);
    localStorage.setItem(`counter-${name}`, 0);
  };

  return (
    <div className={`column ${colorClass}`}>
      <h2>{name}</h2>
      <button className="reset-button" onClick={resetCounter}>
        &#x21bb;
        <span>Reset</span>
      </button>
      <input
        className="counter-input margin-between"
        type="number"
        value={count}
        readOnly
      />
      <button className="button increment" onClick={incrementCounter}>+</button>
      <button className="button decrement" onClick={decrementCounter}>-</button>
      <div className="margin-top margin-bottom">
        <button className="small-button" onClick={() => incrementCounterBy(5)}>+5</button>
        <button className="small-button" onClick={() => incrementCounterBy(10)}>+10</button>
      </div>
      <div className="last-saved">Last saved score: <span className="last-saved-score">{lastSaved}</span></div>
    </div>
  );
};

const CounterApp = () => {
  return (
    <div className="counter-app">
      <Counter name="Rina" colorClass="rina" />
      <Counter name="CJ" colorClass="cj" />
      <Counter name="Rentia" colorClass="rentia" />
    </div>
  );
};

export default CounterApp;