import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";

function Connections() {
  const [showWebsite, setShowWebsite] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const connectionsUrl = "https://www.customconnections.org/game/6652eab6c5c8de0e5599a911";

  useEffect(() => {
    console.log("starting timer NOW", Date.now().toLocaleString());
    setTimeout(() => !showWebsite && setShowWebsite(true), 15_000);
    setTimeout(() => !showButton && setShowButton(true), 30_000);
  }, []);

  return (
    <div style={{ paddingTop: 10 }}>
      <h3>Definitely Connections</h3>
      <p>Click "I have finished Connections!" button when you're done</p>
      {showButton && (
        <Button
          variant='primary'
          size='lg'
          style={{ fontSize: 12 }}
          onClick={() => {
            window.confirm("No cheating!") && (window.location.href = "/rentia22/wordle");
          }}
        >
          I have finished Connections!
        </Button>
      )}

      <iframe
        width='auto'
        height='500'
        src={connectionsUrl}
        title='Connections'
        onLoad={() => setShowWebsite(true)}
        style={{ display: showWebsite ? "block" : "none" }}
      ></iframe>
      {!showWebsite && <Spinner animation='grow' role='status' style={{ color: "#b76e79" }}></Spinner>}

      {showWebsite && (
        <Button
          variant='primary'
          size='sm'
          style={{ fontSize: 12 }}
          onClick={() => {
            setShowWebsite(false);
            setTimeout(() => setShowWebsite(true), 2000);
          }}
        >
          Reload Connections if you made too many mistakes
        </Button>
      )}
    </div>
  );
}

export default Connections;
